<template>
  <div>
    <v-tabs grow v-model="tab"
      ><v-tab key="customer">Kunden</v-tab>
      <v-tab key="distributor">Lieferanten</v-tab>
      <v-tab key="documents">Dokumente</v-tab></v-tabs
    >
    <v-tabs-items v-model="tab">
      <v-tab-item key="customer" class="mt-6 mb-4 mx-4">
        <CustomerFilter @loadData="loadDataCustomer"></CustomerFilter>
        <v-row
          ><v-col lg="6" md="12" sm="12" xs="12">
            <CustomerCount ref="customerCount"></CustomerCount></v-col
          ><v-col lg="6" md="12" sm="12" xs="12">
            <CustomerNew ref="customerNew"></CustomerNew></v-col
        ></v-row>
        <v-row>
          <v-col lg="6" md="12" sm="12" xs="12">
            <CustomerRevenue ref="customerRevenue"></CustomerRevenue
          ></v-col>
          <v-col lg="6" md="12" sm="12" xs="12">
            <CustomerDB ref="customerDB"></CustomerDB
          ></v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <CustomerTop
              ref="customerTop"
              @openCustomerGrouped="openCustomerGrouped"
            ></CustomerTop>
          </v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <CustomerGrouped
              ref="customerGrouped"
              @openCustomerArticles="openCustomerArticles"
            ></CustomerGrouped>
          </v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <CustomerArticles
              ref="customerArticles"
              @openCustomerDocuments="openCustomerDocuments"
            ></CustomerArticles>
          </v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <CustomerDocuments ref="customerDocuments"></CustomerDocuments>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item key="distributor" class="mt-6 mx-4 mb-4">
        <DistributorFilter @loadData="loadDataDistributor"></DistributorFilter>
        <v-row>
          <v-col lg="6" md="12" sm="12" xs="12">
            <DistributorCount ref="distributorCount"></DistributorCount
          ></v-col>
          <v-col lg="6" md="12" sm="12" xs="12">
            <DistributorRevenue ref="distributorRevenue"></DistributorRevenue
          ></v-col>
        </v-row>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DistributorComparison
              ref="distributorComparison"
            ></DistributorComparison>
          </v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DistributorComparisonAmount
              ref="distributorComparisonAmount"
            ></DistributorComparisonAmount>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DistributorTop
              ref="distributorTop"
              @openDistributorGrouped="openDistributorGrouped"
            ></DistributorTop
          ></v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DistributorGrouped
              ref="distributorGrouped"
              @openDistributorArticles="openDistributorArticles"
            ></DistributorGrouped>
          </v-col>
          <v-col lg="12" md="12" sm="12" xs="12"
            ><DistributorArticles
              ref="distributorArticles"
              @openDistributorDocuments="openDistributorDocuments"
            ></DistributorArticles
          ></v-col>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DistributorDocuments
              ref="distributorDocuments"
            ></DistributorDocuments> </v-col></v-row
      ></v-tab-item>
      <v-tab-item key="documents" class="mt-6 mx-4 mb-4">
        <DocumentFilter @loadData="loadDataDocument"></DocumentFilter>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <DocumentCount
              ref="documentCount"
              @openDocumentRevenue="openDocumentRevenue"
            ></DocumentCount>
          </v-col>
          <v-col lg="6" md="12" sm="12" xs="12">
            <DocumentRevenue ref="documentRevenue"></DocumentRevenue>
          </v-col>
          <v-col lg="6" md="12" sm="12" xs="12">
            <DocumentDB ref="documentDB"></DocumentDB>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CustomerCount from '../../components/bi/keyfigures/CustomerCount.vue'
import CustomerRevenue from '../../components/bi/keyfigures/CustomerRevenue.vue'
import CustomerTop from '../../components/bi/keyfigures/CustomerTop.vue'
import DistributorCount from '../../components/bi/keyfigures/DistributorCount.vue'
import DistributorRevenue from '../../components/bi/keyfigures/DistributorRevenue.vue'
import DistributorTop from '../../components/bi/keyfigures/DistributorTop.vue'
import CustomerNew from '../../components/bi/keyfigures/CustomerNew.vue'
import CustomerFilter from '../../components/bi/keyfigures/CustomerFilter.vue'
import DistributorComparison from '../../components/bi/keyfigures/DistributorComparison.vue'
import DistributorFilter from '../../components/bi/keyfigures/DistributorFilter.vue'
import DistributorComparisonAmount from '../../components/bi/keyfigures/DistributorComparisonAmount.vue'
import DocumentFilter from '../../components/bi/keyfigures/DocumentFilter.vue'
import DocumentCount from '../../components/bi/keyfigures/DocumentCount.vue'
import DocumentRevenue from '../../components/bi/keyfigures/DocumentRevenue.vue'
import CustomerGrouped from '../../components/bi/keyfigures/CustomerGrouped.vue'
import CustomerArticles from '../../components/bi/keyfigures/CustomerArticles.vue'
import CustomerDocuments from '../../components/bi/keyfigures/CustomerDocuments.vue'
import DistributorGrouped from '../../components/bi/keyfigures/DistributorGrouped.vue'
import DistributorArticles from '../../components/bi/keyfigures/DistributorArticles.vue'
import DistributorDocuments from '../../components/bi/keyfigures/DistributorDocuments.vue'
import CustomerDB from '../../components/bi/keyfigures/CustomerDB.vue'
import DocumentDB from '../../components/bi/keyfigures/DocumentDB.vue'

export default {
  components: {
    CustomerCount,
    CustomerRevenue,
    CustomerTop,
    CustomerNew,
    DistributorCount,
    DistributorRevenue,
    DistributorTop,
    CustomerFilter,
    DistributorComparison,
    DistributorFilter,
    DistributorComparisonAmount,
    DocumentFilter,
    DocumentCount,
    DocumentRevenue,
    CustomerGrouped,
    CustomerArticles,
    CustomerDocuments,
    DistributorGrouped,
    DistributorArticles,
    DistributorDocuments,
    CustomerDB,
    DocumentDB
  },
  data: () => ({
    tab: ''
  }),
  methods: {
    loadDataCustomer() {
      this.$refs.customerCount.loadData()
      this.$refs.customerNew.loadData()
      this.$refs.customerRevenue.loadData()
      this.$refs.customerDB.loadData()
      this.$refs.customerTop.loadData()
      this.$refs.customerGrouped.show = false
      this.$refs.customerArticles.show = false
      this.$refs.customerDocuments.show = false
    },
    loadDataDistributor() {
      this.$refs.distributorCount.loadData()
      this.$refs.distributorRevenue.loadData()
      this.$refs.distributorComparison.loadData()
      this.$refs.distributorTop.loadData()
      this.$refs.distributorComparisonAmount.loadData()
      this.$refs.distributorGrouped.show = false
      this.$refs.distributorArticles.show = false
      this.$refs.distributorDocuments.show = false
    },
    loadDataDocument() {
      this.$refs.documentCount.loadData()
      this.$refs.documentRevenue.loadData()
      this.$refs.documentDB.loadData()
    },
    openDocumentRevenue(formGroup) {
      console.warn(formGroup)
      this.$refs.documentRevenue.loadData(formGroup, true)
      this.$refs.documentDB.loadData(formGroup, true)
    },
    openCustomerGrouped(data, kdSortname) {
      this.$refs.customerGrouped.open(data, kdSortname)
      this.$refs.customerArticles.show = false
      this.$refs.customerDocuments.show = false
    },
    openCustomerArticles(data, kdSortname, grouped) {
      this.$refs.customerArticles.open(data, kdSortname, grouped)
      this.$refs.customerDocuments.show = false
    },
    openCustomerDocuments(data) {
      this.$refs.customerDocuments.open(data)
    },
    openDistributorGrouped(data, kdSortname) {
      this.$refs.distributorGrouped.open(data, kdSortname)
      this.$refs.distributorArticles.show = false
      this.$refs.distributorDocuments.show = false
    },
    openDistributorArticles(data, kdSortname, grouped) {
      this.$refs.distributorArticles.open(data, kdSortname, grouped)
      this.$refs.distributorDocuments.show = false
    },
    openDistributorDocuments(data) {
      this.$refs.distributorDocuments.open(data)
    }
  }
}
</script>

<style></style>
