<template>
  <v-menu bottom offset-y class="mr-3">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
        <span>{{ typeView }}</span>
        <v-icon right>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <!--Liste zum Auswählen der Art der Darstellung-->
    <v-list>
      <v-list-item @click="changeTypeView('day')">
        <v-list-item-title>Tag</v-list-item-title>
      </v-list-item>
      <v-menu offset-x left>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="white">
            <v-list-item-title>Woche</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item @click="changeTypeView('week', 5)">
            <v-list-item-title>5-Tage</v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeTypeView('week', 7)">
            <v-list-item-title>7-Tage</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item @click="changeTypeView('month')">
        <v-list-item-title>Monat</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" class="white">
          <v-list-item-title>Geteilte Ansicht</v-list-item-title>
        </v-list-item>
      </template>
      <v-list>
        <v-list-item @click="changeTypeView('scope')">
          <v-list-item-title>Bereich</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeTypeView('location')">
          <v-list-item-title>Lokation</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-menu>
</template>

<script>
import userSettingsService from '@/services/settings/userSettingsService'

export default {
  data: () => ({ typeView: '7-Tage' }),
  methods: {
    changeTypeView(newView, days = 0) {
      switch (newView) {
        case 'week':
          switch (days) {
            case 5:
              this.typeView = '5-Tage'
              break
            case 7:
              this.typeView = '7-Tage'
              break

            default:
              break
          }
          break
        case 'day':
          this.typeView = 'Tag'
          break
        case 'month':
          this.typeView = 'Monat'
          break
        case 'scope':
          this.typeView = 'Bereich'
          break
        case 'location':
          this.typeView = 'Lokation'
          break

        default:
          break
      }
      this.$emit('changeTypeOfView', newView, days)
    }
  },
  mounted() {
    var type = userSettingsService.getValueFromName('typeOfCalendarOverview')

    if (type !== null) {
      if (type === '5week') {
        this.changeTypeView('week', 5)
      } else if (type === '7week') {
        this.changeTypeView('week', 7)
      } else {
        this.changeTypeView(type)
      }
    } else {
      this.changeTypeView('week', 7)
    }
  }
}
</script>

<style scoped></style>
