<template>
  <div>
    <v-expansion-panels
      accordion
      class="mb-4"
      flat
      style="border: 1px #757575 solid"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>
            <v-icon class="mr-2">mdi-filter</v-icon>Filter
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content
          ><v-row class="mb-n9">
            <v-col cols="6">
              <v-select
                outlined
                dense
                label="Von (Jahr)"
                v-model="filter.startYear"
                :items="years"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                outlined
                dense
                label="Bis (Jahr)"
                :items="years"
                v-model="filter.endYear"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col cols="12">
              <v-select
                outlined
                dense
                label="Kategorie"
                :items="categories"
                v-model="filter.filterCategory"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col cols="6">
              <v-checkbox
                v-model="filter.toCurrentMonth"
                label="Nur bis aktuellen Monat"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="filter.wj"
                label="Wirtschaftsjahr"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-btn
              color="primary"
              elevation="0"
              class="mr-5 ml-3"
              @click="updateFilter"
              >Übernehmen</v-btn
            >
            <v-btn color="error" elevation="0" @click="clearFilter"
              >Zurücksetzen</v-btn
            >
          </v-row></v-expansion-panel-content
        ></v-expansion-panel
      ></v-expansion-panels
    >
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      categories: [
        'Standort',
        'Artikeltyp',
        'Artikelkategorie',
        'Artikelgruppe',
        'Status',
        'Erlösgruppe'
      ],
      filter: {
        filterCategory: '',
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: ''
      },
      years: []
    }
  },
  mounted() {
    this.filter.filterCategory = 'Standort'
    this.loadMinYear()
    this.updateFilter()
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date()
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year)
        }
      })
    },
    updateFilter() {
      axios
        .post('v1/statistics/revenue/filter/filtered', this.filter)
        .then(() => {
          this.$emit('loadData')
        })
    },
    clearFilter() {
      this.filter = {
        filterCategory: 'Standort',
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: ''
      }
      this.updateFilter()
    }
  }
}
</script>

<style></style>
