<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <v-data-table
          v-show="show"
          :headers="headers"
          :items="items"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                class="mt-8"
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                outlined
                dense
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.fart="{ item }">
            <router-link
              :to="{
                name: 'crmAddressDocumentDetailed',
                params: { lfdnr: item.subkdnr, wf_cid: item.wfcid }
              }"
              >{{ item.fart + ' ' + item.nr }}</router-link
            >
          </template>
          <template v-slot:item.date="{ item }">
            {{ dateTimeService.getGermanDateFormatWithoutTime(item.date) }}
          </template>
          <template v-slot:item.revenue="{ item }">
            {{ currencyFormatService.formatEuro(item.revenue) }}
          </template>
          <template v-slot:item.db="{ item }">
            {{ currencyFormatService.formatEuro(item.db) }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount + ' ' + item.amountType }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import currencyFormatService from '@/services/formatters/currencyFormatService.js'
import dateTimeService from '@/services/cal/dateTimeService'

export default {
  data: () => ({
    show: false,
    headers: [
      { text: 'Dokument', value: 'fart' },
      { text: 'Datum', value: 'date' },
      { text: 'Umsatz', value: 'revenue' },
      { text: 'Deckungsbeitrag', value: 'db' },
      { text: 'Menge', value: 'amount' }
    ],
    items: [],
    currencyFormatService,
    search: '',
    dateTimeService
  }),
  methods: {
    open(data) {
      this.items = data
      this.show = true
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
