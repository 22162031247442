<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="quickUpload"
    scrollable
  >
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar fixed top flat>
          <v-icon @click="quickUpload = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">
            Bilderupload - Adressenwahl
          </v-toolbar-title></v-toolbar
        >
      </v-card-title>

      <v-card-text class="pt-5 pl-10">
        <p
          v-if="
            appointment.addresses == null || appointment.addresses.length == 0
          "
        >
          Dem Termin sind keine Adressen zugeordnet.
        </p>
        <p v-else>
          Bitte wählen Sie eine Adresse zur Zuordnung:
        </p>

        <v-select
          :items="appointment.addresses"
          :disabled="
            appointment.addresses == null || appointment.addresses.length == 0
          "
          v-model="selectedAddress"
          item-text="name"
          item-value="name"
          return-object
        >
        </v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-bind="attrs"
          color="green darken-1"
          class="white--text"
          :disabled="selectedAddress == null"
          @click="showAddressPicturesDialog = true"
          >OK</v-btn
        >
        <v-btn text @click="quickUpload = false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
    <AddressPicturesDialog
      v-if="showAddressPicturesDialog"
      @close-dialog="closeAddressPicturesDialog()"
      :address="selectedAddress"
      :opened="true"
    />
  </v-dialog>
</template>

<script>
import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue'

export default {
  data: () => ({
    quickUpload: false,
    appointment: [],
    selectedAddress: null,
    showAddressPicturesDialog: false
  }),
  components: {
    AddressPicturesDialog
  },
  methods: {
    open(appointment) {
      this.appointment = appointment
      this.quickUpload = true
    },
    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false
    }
  }
}
</script>

<style></style>
