<template>
  <div>
    <v-expansion-panels
      accordion
      class="mb-4"
      flat
      style="border: 1px #757575 solid"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>
            <v-icon class="mr-2">mdi-filter</v-icon>Filter
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="mb-n9">
            <v-col lg="6" xs="12" sm="12"
              ><v-select
                outlined
                dense
                label="Von (Jahr)"
                :items="years"
                v-model="filter.startYear"
              ></v-select
            ></v-col>
            <v-col lg="6" xs="12" sm="12"
              ><v-select
                outlined
                dense
                label="Bis (Jahr)"
                :items="years"
                v-model="filter.endYear"
              ></v-select
            ></v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col lg="6" xs="12" sm="12"
              ><v-select
                outlined
                dense
                label="Von (Monat)"
                :items="months"
                v-model="filter.startMonth"
              ></v-select
            ></v-col>
            <v-col lg="6" xs="12" sm="12"
              ><v-select
                outlined
                dense
                label="Bis (Monat)"
                :items="months"
                v-model="filter.endMonth"
              ></v-select
            ></v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col lg="6" xs="12" sm="12">
              <v-select
                dense
                outlined
                label="Standort"
                :items="locations"
                item-text="name"
                v-model="filter.locations"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>

            <v-col lg="6" xs="12" sm="12">
              <v-select
                dense
                outlined
                label="Status"
                :items="states"
                item-text="name"
                v-model="filter.states"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col lg="4" xs="12" sm="12">
              <v-select
                dense
                outlined
                label="Artikeltyp"
                :items="articleTypes"
                item-text="name"
                v-model="filter.articleTypes"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
                @change="articleTypesChanged"
              ></v-select>
            </v-col>
            <v-col lg="4" xs="12" sm="12">
              <v-select
                dense
                outlined
                label="Artikelkategorie"
                :items="articleCategories"
                item-text="name"
                v-model="filter.articleCategories"
                :item-value="item => item"
                chips
                :disabled="filter.articleTypes.length === 0"
                small-chips
                deletable-chips
                multiple
                @change="articleCategoriesChanged"
              ></v-select>
            </v-col>
            <v-col lg="4" xs="12" sm="12">
              <v-select
                dense
                outlined
                label="Artikelgruppe"
                :items="articleGroups"
                item-text="name"
                v-model="filter.articleGroups"
                :item-value="item => item"
                chips
                :disabled="filter.articleCategories.length === 0"
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col lg="4" xs="12" sm="12">
              <v-checkbox
                v-model="filter.toCurrentMonth"
                label="Nur bis aktuellen Monat"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="filter.wj"
                label="Wirtschaftsjahr"
              ></v-checkbox>
            </v-col>
            <v-col lg="4" xs="12" sm="12">
              <v-checkbox
                v-model="filter.db"
                label="Deckungsbeitrag"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-btn
              color="primary"
              elevation="0"
              class="mr-5 ml-3"
              @click="updateFilter"
              >Übernehmen</v-btn
            >
            <v-btn color="error" elevation="0" @click="clearFilter"
              >Zurücksetzen</v-btn
            >
          </v-row></v-expansion-panel-content
        >
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      years: [],
      months: [
        'Jan.',
        'Feb.',
        'März',
        'Apr.',
        'Mai',
        'Jun.',
        'Jul.',
        'Aug.',
        'Sept.',
        'Okt',
        'Nov.',
        'Dez.'
      ],
      locations: [],
      states: [],
      articleTypes: [],
      articleCategories: [],
      articleGroups: [],
      filter: {
        startYear: '',
        endYear: '',
        startMonth: '',
        endMonth: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      }
    }
  },
  mounted() {
    this.loadMinYear()
    this.updateFilter()
    this.loadFilterData()
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date()
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year)
        }
      })
    },
    loadFilterData() {
      axios.get('v1/statistics/filter/locations').then(data => {
        this.locations = data.data
      })
      axios.get('v1/statistics/filter/states').then(data => {
        this.states = data.data
      })
      var articleWrapper = {
        articleCategories: [],
        articleTypes: [],
        articleGroups: []
      }
      axios
        .post('v1/statistics/filter/articleTypes', articleWrapper)
        .then(data => {
          this.articleTypes = data.data
        })
      axios
        .post('v1/statistics/filter/articleCategories', articleWrapper)
        .then(data => {
          this.articleCategories = data.data
        })
      axios
        .post('v1/statistics/filter/articleGroups', articleWrapper)
        .then(data => {
          this.articleGroups = data.data
        })
    },
    updateFilter() {
      axios
        .post('v1/statistics/revenue/filter/comparison', this.filter)
        .then(() => {
          this.$emit('loadData')
        })
    },
    clearFilter() {
      this.filter = {
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      }
      this.updateFilter()
    },
    articleTypesChanged() {
      var articleWrapper = {
        articleCategories: [],
        articleTypes: this.filter.articleTypes,
        articleGroups: []
      }
      axios
        .post('v1/statistics/revenue/filter/articleCategories', articleWrapper)
        .then(data => {
          this.articleCategories = data.data
        })
    },
    articleCategoriesChanged() {
      var articleWrapper = {
        articleCategories: this.articleCategories,
        articleTypes: [],
        articleGroups: []
      }
      axios
        .post('v1/statistics/revenue/filter/articleGroups', articleWrapper)
        .then(data => {
          this.articleGroups = data.data
        })
    }
  }
}
</script>

<style></style>
