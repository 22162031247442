<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    @click:row="openDetailView"
    class="datatable-row-pointer "
    ><template v-slot:top>
      <v-toolbar flat color="white">
        <v-text-field
          class="mt-8"
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
        ></v-text-field>
        <v-btn elevation="0" color="primary" class="mt-1 ml-5" @click="addTask"
          ><v-icon class="mr-2">mdi-plus </v-icon>Neue Aufgabe</v-btn
        >
      </v-toolbar>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:item.status.key.lfdnr="{ item }"
      ><v-simple-checkbox
        color="primary"
        v-model="item.status.done"
      ></v-simple-checkbox
    ></template>
    <template v-slot:item.lfdnr="{ item }">
      <v-chip color="primary">{{ item.lfdnr }}</v-chip>
    </template>

    <template v-slot:item.dueDate="{ item }"
      ><v-icon class="mr-2 mb-1">mdi-clock</v-icon
      >{{ dateTimeService.getGermanDateFormat(item.dueDate) }}</template
    >
  </v-data-table>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'

export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Abgeschlossen',
        value: 'status.key.lfdnr',
        align: 'start'
      },
      {
        text: 'Nr.',
        value: 'lfdnr',
        align: 'start'
      },
      { text: 'Betreff', value: 'title.title' },
      {
        text: 'Bis',
        value: 'dueDate'
      }
    ],
    search: '',
    dateTimeService
  }),
  methods: {
    addTask() {
      this.$router.push('/task/new')
    },
    openDetailView(value) {
      this.$router.push({ name: 'TaskDetails', params: { lfdnr: value.lfdnr } })
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.centered-input >>> input {
  text-align: center;
}
</style>
