<template>
  <div>
    <v-sheet min-height="64" max-width="100%">
      <!--Zum heutigen Tag springen Button-->
      <div class="py-2">
        <v-row align="center">
          <v-col cols="12" sm="12" md="6" lg="6" xl="4">
            <div
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-lg-center justify-xl-start align-center"
            >
              <v-btn
                fab
                text
                class="mx-sm-5"
                color="grey darken-2"
                @click="prevPage"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <v-btn
                fab
                text
                class="mx-xs-2 mx-sm-5"
                color="grey darken-2"
                @click="setToday"
                ><v-icon>mdi-calendar-today</v-icon>
              </v-btn>

              <h2 class="mx-sm-5 text-center">
                {{ this.monthYearTitle }}
              </h2>

              <v-btn
                fab
                text
                class="mx-sm-5"
                color="grey darken-2"
                @click="navigationDatePicker = true"
              >
                <v-icon>mdi-calendar-cursor</v-icon>
              </v-btn>

              <v-btn
                fab
                text
                class="mx-sm-5"
                color="grey darken-2"
                @click="nextPage"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="4">
            <div
              class="d-flex justify-center justify-xs-center justify-sm-center justify-md-start justify-lg-center justify-xl-center align-center"
            >
              <v-btn
                icon
                fab
                color="grey darken-2"
                class="mx-2 mx-xs-2 mx-sm-5"
                @click="zoom(false)"
                :disabled="zoomValue === minZoomValue"
              >
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
              <v-btn
                v-show="currentDisplay !== 'xs'"
                :text="zoomValue === resetZoomValue"
                depressed
                class="mx-2 mx-xs-2 mx-sm-5"
                @click="zoom(false, true)"
              >
                {{ zoomValue }}
              </v-btn>

              <div
                class="mx-2 mx-xs-2 mx-sm-10"
                v-if="
                  currentDisplay === 'xs' ||
                    currentDisplay === 'sm' ||
                    currentDisplay === 'md'
                "
              >
                <TypeOfCalendarOverviewMenu
                  ref="typeOfCalendarOverviewMenu"
                  @changeTypeOfView="changeTypeOfView"
                />
              </div>

              <v-btn
                icon
                fab
                class="mx-2 mx-xs-2 mx-sm-5 "
                color="grey darken-2"
                @click="zoom(true)"
                :disabled="zoomValue === maxZoomValue"
              >
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="12"
            xl="4"
            v-if="
              currentDisplay !== 'xs' &&
                currentDisplay !== 'sm' &&
                currentDisplay !== 'md'
            "
          >
            <div
              class="d-flex justify-center justify-xs-center justify-sm-center justify-md-start justify-lg-center justify-xl-end align-center"
            >
              <v-btn
                elevation="0"
                color="primary"
                class="mx-3"
                @click="addAppointment"
                ><v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn
                outlined
                class="mx-3"
                color="grey darken-2"
                @click="$refs.appointmentSearch.open()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                outlined
                class="mx-3"
                color="grey darken-2"
                @click="openFilter"
                v-if="!isFilterSet"
                ><v-icon>mdi-filter</v-icon></v-btn
              >
              <v-btn
                outlined
                class="mx-3"
                color="grey darken-2"
                @click="openFilter"
                v-if="isFilterSet"
                ><v-icon>mdi-filter-cog</v-icon></v-btn
              >
              <v-btn
                outlined
                class="mx-3"
                color="grey darken-2"
                @click="clearFilter"
                v-if="isFilterSet"
                ><v-icon>mdi-filter-off</v-icon></v-btn
              >
              <div class="mx-3">
                <TypeOfCalendarOverviewMenu
                  ref="typeOfCalendarOverviewMenu"
                  @changeTypeOfView="changeTypeOfView"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="
          currentDisplay === 'xs' ||
            currentDisplay == 'sm' ||
            currentDisplay === 'md'
        "
      >
        <v-speed-dial
          v-model="fab"
          bottom
          absolute
          fixed
          right
          direction="top"
          transition="slide-y-transition"
        >
          <template v-slot:activator>
            <v-btn class="elevation-0" large v-model="fab" color="primary" fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-menu
              </v-icon>
            </v-btn>
          </template>
          <v-btn fab color="primary" @click="addAppointment"
            ><v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn fab color="primary" @click="$refs.appointmentSearch.open()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn fab color="primary" @click="openFilter"
            ><v-icon>{{
              isFilterSet ? 'mdi-filter-cog' : 'mdi-filter'
            }}</v-icon></v-btn
          >
          <v-btn fab color="primary" @click="clearFilter" v-if="isFilterSet"
            ><v-icon>mdi-filter-off</v-icon></v-btn
          >
        </v-speed-dial>
      </div>
      <!-- Mobile Floating Button -->

      <!--Aktuelles/Ausgewähltes Monat-->
    </v-sheet>
    <v-dialog v-model="navigationDatePicker" max-width="350px">
      <v-card>
        <v-card-title class="mx-0 px-0 my-0 py-0">
          <v-toolbar color="primary" flat fixed top>
            <v-icon @click="navigationDatePicker = false">mdi-close</v-icon>
            <v-toolbar-title class="flex text-center">
              Datum wählen
            </v-toolbar-title></v-toolbar
          >
        </v-card-title>
        <v-card-text class="text-center mt-8">
          <v-date-picker
            v-model="navigationDate"
            no-title
            scrollable
            first-day-of-week="1"
            color="primary"
          >
            <v-spacer></v-spacer>
            <!--Button um Datepicker zu schließen-->
            <v-btn text color="primary" @click="navigationDatePicker = false"
              >Abbrechen</v-btn
            >
            <!--Button welcher Datepicker schließt und das ausgewählte Datum speichert-->
            <v-btn
              text
              color="primary"
              @click="navigateToDate(), (navigationDatePicker = false)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Auswahl der Darstellung, Monat, Woche, Tag-->
    <AppointmentSearch
      ref="appointmentSearch"
      @editAppointment="editAppointment"
      @deleteAppointment="deleteAppointment"
      @setToDate="setToDate"
    />
    <AppointmentFilter ref="filter" @applyFilter="applyFilter" />
  </div>
</template>

<script>
import TypeOfCalendarOverviewMenu from '@/components/calendar_v2/TypeOfCalendarOverviewMenu.vue'
import AppointmentSearch from '@/components/calendar_v2/AppointmentSearch.vue'
import AppointmentFilter from '@/components/calendar_v2/AppointmentFilter.vue'
import { mapState } from 'vuex'
import userSettingsService from '../../services/settings/userSettingsService'

export default {
  data: () => ({
    fab: false,
    title: '',
    navigationDatePicker: false,
    navigationDate: null,
    isFilterSet: false,
    zoomValue: '100%',
    resetZoomValue: '100%',
    minZoomValue: '20%',
    maxZoomValue: '200%',
    userSettingsService,
    typeView: ''
  }),
  computed: {
    ...mapState({
      settingsCal: state => state.user.settings_cal
    }),
    currentDisplay() {
      return this.$vuetify.breakpoint.name
    }
  },
  components: {
    TypeOfCalendarOverviewMenu,
    AppointmentSearch,
    AppointmentFilter
  },
  methods: {
    updateZoomValue(value) {
      this.zoomValue = value
    },
    openFilter() {
      this.$refs.filter.open()
    },
    clearFilter() {
      this.$refs.filter.clearFilter()
      this.isFilterSet = false
      this.$emit('clearFilter')
    },
    applyFilter() {
      this.isFilterSet = true
      this.$emit('applyFilter')
    },
    nextPage() {
      this.$emit('nextPage')
    },
    prevPage() {
      this.$emit('prevPage')
    },
    setToday() {
      this.$emit('setToday')
    },
    setToDate(date) {
      this.$emit('setToDate', date)
    },
    getTypeOfView() {
      return this.$refs.typeOfCalendarOverviewMenu.typeView
    },
    changeTypeOfView(newView, days) {
      this.typeView = this.getTypeOfView()
      this.$emit('changeTypeOfView', newView, days)
    },
    navigateToDate() {
      this.$emit('navigateToDate', this.navigationDate)
    },
    addAppointment() {
      this.$emit('addAppointment')
    },
    editAppointment(appointment) {
      this.$emit('editAppointment', appointment)
    },
    deleteAppointment(appointment) {
      this.$emit('deleteAppointment', appointment)
    },
    zoom(plus, reset = false) {
      this.$emit('zoom', plus, reset)
    },
    setInitialZoom() {
      if (userSettingsService.getValueFromName('zoom') !== null) {
        this.zoomValue =
          Math.round(
            (userSettingsService.getValueFromName('zoom') / 50) * 100
          ) + '%'
        this.resetZoomValue =
          Math.round(
            (userSettingsService.getValueFromName('zoom') / 50) * 100
          ) + '%'
      } else {
        this.zoomValue = '100%'
      }
    }
  },
  mounted() {
    this.typeView = this.getTypeOfView()
    this.setInitialZoom()
  },
  props: ['monthYearTitle']
}
</script>

<style></style>
