<template>
  <v-dialog v-model="appointmentSearch" scrollable max-width="1000">
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="appointmentSearch = false">mdi-close</v-icon>
          <!--Überschrift-->
          <v-toolbar-title class="flex text-center"
            >Terminsuche</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mx-0 px-0 my-0 py-0">
        <v-simple-table>
          <tbody>
            <tr class="mt-2">
              <td colspan="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchText"
                      append-icon="mdi-magnify"
                      label="Textsuche"
                      hide-details
                      class="pa-3"
                      v-on:keyup.enter="searchAppointments"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span
                    >Suche nach Betreff, Adressen, Objekte und Ressourcen</span
                  >
                </v-tooltip>
              </td>
            </tr>
            <v-data-table
              ref="vuetable"
              :headers="headers"
              :items="appointments"
              :items-per-page="10"
              class="elevation-1"
              ><template v-slot:no-data> Keine Daten gefunden. </template
              ><template v-slot:item.actions="{ item }">
                <v-btn
                  fab
                  small
                  text
                  outlined
                  elevation="0"
                  class="mr-2 col-xl-5"
                  @click="setToDate(item)"
                  ><v-icon>mdi-calendar-cursor-outline</v-icon></v-btn
                >
                <v-btn
                  fab
                  small
                  text
                  outlined
                  elevation="0"
                  class="mr-2 col-xl-5"
                  @click="editAppointment(item)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  outlined
                  color="red"
                  class="col-xl-5"
                  @click="tryDeleteAppointment(item)"
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template></v-data-table
            >
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="appointmentSearch = false"
          >Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
    <Loading ref="loading"></Loading>
    <YouSure ref="sureToDelete" @actionPerformed="deleteAppointment"></YouSure>
  </v-dialog>
</template>

<script>
import Loading from '@/components/calendar_v2/Loading.vue'
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService.js'
import YouSure from '@/components/calendar_v2/YouSure.vue'

export default {
  computed: {
    ...mapState({
      foundAppointments: state => state.calAppointments.foundAppointments
    })
  },
  data: () => ({
    appointmentSearch: false,
    searchText: '',
    appointments: [],
    headers: [
      { text: 'Betreff', value: 'title', align: 'start' },
      { text: 'Von', value: 'startFormatted', align: 'start' },
      { text: 'Bis', value: 'endFormatted', align: 'start' },
      { text: 'Ganztägig', value: 'allDayFormatted', align: 'center' },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    toDelete: []
  }),
  components: {
    Loading,
    YouSure
  },
  methods: {
    open() {
      this.appointments = []
      this.searchText = ''
      this.appointmentSearch = true
    },
    searchAppointments() {
      this.$refs.loading.open('Termine werden geladen ...')
      this.$store.dispatch('searchAppointments', this.searchText).then(() => {
        this.appointments = this.foundAppointments
        this.appointments.forEach(element => {
          element.startFormatted = dateTimeService.getGermanDateFormat(
            element.start
          )
          element.endFormatted = dateTimeService.getGermanDateFormat(
            element.end
          )
          if (element.allDay) element.allDayFormatted = 'Ja'
          else element.allDayFormatted = 'Nein'
        })
        this.$refs.loading.close()
      })
    },
    setToDate(appointment) {
      this.$emit('setToDate', appointment.start)
      this.appointmentSearch = false
    },
    editAppointment(appointment) {
      this.appointmentSearch = false
      this.$emit('editAppointment', appointment)
    },
    tryDeleteAppointment(appointment) {
      this.toDelete = appointment
      this.$refs.sureToDelete.open('Wollen Sie den Termin löschen?', 'LÖSCHEN')
    },
    deleteAppointment() {
      this.$emit('deleteAppointment', this.toDelete)
      this.appointments.splice(this.appointments.indexOf(this.toDelete), 1)
    }
  }
}
</script>

<style></style>
