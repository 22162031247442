<template>
  <v-dialog
    v-model="appointmentHistory"
    scrollable
    max-width="1000px"
    persistent
  >
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="appointmentHistory = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Terminverlauf</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-timeline>
          <v-timeline-item
            v-for="entry in history"
            :key="entry.appointment.mdat"
            ><v-card elevation="0" style="border: 1px #e0e0e0 solid"
              ><v-card-title>
                {{
                  dateTimeService.getGermanDateFormat(entry.appointment.mdat)
                }}</v-card-title
              >
              <v-card-subtitle>{{ entry.appointment.muser }}</v-card-subtitle>
              <v-card-text v-for="change in entry.changes" :key="change">
                <b>{{ loadGermanPropertyName(Object.keys(change)[0]) }}:</b>
                <br />
                {{ loadValue(change, 0) }}
                <v-icon>mdi-chevron-double-right</v-icon>
                {{ loadValue(change, 1) }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="primary"
          @click="appointmentHistory = false"
          elevation="0"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'

export default {
  data: () => ({
    appointmentHistory: false,
    history: [],
    dateTimeService
  }),
  components: {},
  methods: {
    open(history) {
      this.appointmentHistory = true
      this.history = history
    },
    loadDescriptionProperty(prop) {
      switch (prop) {
        case 'scope':
          return 'name'
        case 'state':
          return 'description'
        case 'location':
          return 'name'
        case 'type':
          return 'name'
      }
    },
    loadGermanPropertyName(prop) {
      switch (prop) {
        case 'scope':
          return 'Bereich'
        case 'state':
          return 'Status'
        case 'location':
          return 'Lokation'
        case 'type':
          return 'Terminart'
        case 'start':
          return 'Von'
        case 'end':
          return 'Bis'
        case 'allDay':
          return 'Ganztägig'
        case 'duration':
          return 'Dauer'
        case 'firstAddressKdSortname':
          return 'Erste Adresse'
        case 'telNr':
          return 'Tel.-Nr.'
        case 'title':
          return 'Betreff'
      }
    },
    loadValue(change, pos) {
      if (change[Object.keys(change)[0]][pos] != null) {
        if (
          Object.keys(change)[0] !== 'start' &&
          Object.keys(change)[0] !== 'end' &&
          Object.keys(change)[0] != 'duration' &&
          Object.keys(change)[0] != 'allDay' &&
          Object.keys(change)[0] != 'firstAddressKdSortname' &&
          Object.keys(change)[0] != 'telNr' &&
          Object.keys(change)[0] != 'title'
        ) {
          return change[Object.keys(change)[0]][pos][
            this.loadDescriptionProperty(Object.keys(change)[0])
          ]
        } else if (
          Object.keys(change)[0] == 'start' ||
          Object.keys(change)[0] == 'end'
        ) {
          return dateTimeService.getGermanDateFormat(
            change[Object.keys(change)[0]][pos]
          )
        } else if (Object.keys(change)[0] == 'duration') {
          var dur = dateTimeService.getDurationSplit(
            change[Object.keys(change)[0]][pos]
          )
          return `${dur.days} Tage ${dur.hours} Stunden ${dur.minutes} Minuten`
        } else if (Object.keys(change)[0] == 'allDay') {
          return change[Object.keys(change)[0]][pos] == true ? 'ja' : 'nein'
        } else {
          return change[Object.keys(change)[0]][pos]
        }
      } else {
        return 'Kein Eintrag'
      }
    }
  }
}
</script>

<style></style>
