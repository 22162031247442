<template>
  <v-dialog
    persistent
    v-model="moveAppointmentModal"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <!--Überschrift-->
          <v-toolbar-title class="flex text-center"
            >Termin verschieben</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mx-0 px-0 my-3 py-0" justify-center>
        <template>
          <div align="center">
            <span class="text-h4">{{ this.appointment.title }}</span>
          </div>

          <v-container fluid>
            <v-row>
              <v-col cols="5.5">
                <v-card elevation="0" style="border:1px solid #757575">
                  <v-card-text
                    ><div>
                      <p>
                        <v-icon>mdi-calendar-start-outline</v-icon>
                        {{
                          dateTimeService.getGermanDateFormatWithoutTime(
                            this.currentDraggedEvent.start
                          )
                        }}
                      </p>
                      <p v-if="!this.currentDraggedEvent.wholeday">
                        <v-icon>mdi-clock-start</v-icon>
                        {{
                          dateTimeService.getTimeStringFromDateObject(
                            new Date(this.currentDraggedEvent.start)
                          )
                        }}
                      </p>
                    </div>
                    <div>
                      <p>
                        <v-icon>mdi-calendar-end-outline</v-icon>
                        {{
                          dateTimeService.getGermanDateFormatWithoutTime(
                            this.currentDraggedEvent.end
                          )
                        }}
                      </p>
                      <p v-if="!this.currentDraggedEvent.wholeday">
                        <v-icon>mdi-clock-end</v-icon>
                        {{
                          dateTimeService.getTimeStringFromDateObject(
                            new Date(this.currentDraggedEvent.end)
                          )
                        }}
                      </p>
                      <p>
                        <v-icon>mdi-texture-box</v-icon>
                        {{ this.currentDraggedEvent.scope.name }}
                      </p>
                      <p>
                        <v-icon>mdi-map-marker</v-icon>
                        {{ this.currentDraggedEvent.location.name }}
                      </p>
                    </div></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col
                cols="1"
                style="display: flex; justify-content: center;align-items: center;"
              >
                <v-icon size="48">mdi-chevron-double-right</v-icon>
              </v-col>
              <v-col cols="5.5">
                <v-card elevation="0" style="border: 1px solid #757575">
                  <v-card-text>
                    <div>
                      <p>
                        <v-icon>mdi-calendar-start-outline</v-icon>
                        {{
                          dateTimeService.getGermanDateFormatWithoutTime(
                            this.appointment.start
                          )
                        }}
                      </p>
                      <p v-if="!this.appointment.wholeday">
                        <v-icon>mdi-clock-start</v-icon>
                        {{
                          dateTimeService.getTimeStringFromDateObject(
                            new Date(this.appointment.start)
                          )
                        }}
                      </p>
                    </div>
                    <div>
                      <p>
                        <v-icon>mdi-calendar-end-outline</v-icon>
                        {{
                          dateTimeService.getGermanDateFormatWithoutTime(
                            this.appointment.end
                          )
                        }}
                      </p>
                      <p v-if="!this.appointment.wholeday">
                        <v-icon>mdi-clock-end</v-icon>
                        {{
                          dateTimeService.getTimeStringFromDateObject(
                            new Date(this.appointment.end)
                          )
                        }}
                      </p>
                      <p>
                        <v-icon>mdi-texture-box</v-icon>
                        {{ this.appointment.scope.name }}
                      </p>
                      <p>
                        <v-icon>mdi-map-marker</v-icon>
                        {{ this.appointment.location.name }}
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          depressed
          @click="
            moveAppointmentModal = false
            undo()
          "
          >Änderung verwerfen</v-btn
        >
        <v-btn
          color="primary"
          text
          @click="
            moveAppointmentModal = false
            save()
          "
          >Änderung übernehmen</v-btn
        >
      </v-card-actions>
    </v-card>
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
  </v-dialog>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'
import WarningDialog from '@/components/core/WarningDialog.vue'

export default {
  data: () => ({
    moveAppointmentModal: false,
    appointment: [],
    dateTimeService,
    currentDraggedEvent: []
  }),
  components: {
    WarningDialog
  },
  methods: {
    open(currentDraggedEvent, appointment) {
      if (
        currentDraggedEvent.start == appointment.start ||
        currentDraggedEvent.end == appointment.end
      ) {
        this.moveAppointmentModal = false
        return false
      }
      this.moveAppointmentModal = true
      this.currentDraggedEvent = currentDraggedEvent
      this.appointment = appointment
    },
    save(isSerial = false, userChose = false) {
      console.info('🎉 save')
      if (this.appointment.serialNumber != 0 && userChose == false) {
        var actions = [
          {
            action: 'single',
            text: 'Einzeltermin bearbeiten',
            icon: 'mdi-calendar'
          },
          {
            action: 'serial',
            text: 'Serientermin bearbeiten',
            icon: 'mdi-calendar-multiple'
          }
        ]
        this.$refs.warning.open(
          'Dieser Termin ist Teil einer Terminserie',
          actions
        )
      } else this.$emit('save', this.appointment, isSerial)
    },
    warningActionPerformed(action) {
      var isSerial = action === 'serial' ? true : false
      this.save(isSerial, true, true)
    },
    undo() {
      console.info('🎉 undo')
      this.$emit('undo', this.currentDraggedEvent)
    }
  }
}
</script>

<style></style>
