<template>
  <apexchart type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import axios from 'axios'

export default {
  props: {},
  data: function() {
    return {
      series: [],
      isYearSelected: false,
      month: ''
    }
  },
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {
          text: 'Monatsvergleich'
        },
        xaxis: {
          labels: {
            formatter: value => {
              switch (value) {
                case '1':
                  return 'Jan.'
                case '2':
                  return 'Feb.'
                case '3':
                  return 'März'
                case '4':
                  return 'Apr.'
                case '5':
                  return 'Mai'
                case '6':
                  return 'Jun.'
                case '7':
                  return 'Jul.'
                case '8':
                  return 'Aug.'
                case '9':
                  return 'Sept.'
                case '10':
                  return 'Okt.'
                case '11':
                  return 'Nov.'
                case '12':
                  return 'Dez.'
                default:
                  return value
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: (e, chart, config) => {
              var year = this.series[config.seriesIndex].name
              var month =
                config.w.globals.seriesX[config.seriesIndex][
                  config.dataPointIndex
                ]
              if (month !== undefined && year !== undefined) {
                this.month = month
              }
            }
          }
        }
      }
    }
  },

  mounted() {},

  methods: {
    loadData() {
      axios.get(`v1/statistics/revenue/month`).then(data => {
        var seriesData = []
        var years = [...new Set(data.data.map(item => item.year))]
        var months = [...new Set(data.data.map(item => item.month))]
        years.forEach(year => {
          var yearData = data.data.filter(item => item.year === year)
          var series = {
            name: year,
            data: []
          }

          // Populate series data with revenue for each month
          months.forEach(month => {
            var monthData = yearData.find(item => item.month === month)
            if (monthData) {
              series.data.push({
                x: monthData.month + '',
                y: monthData.revenue
              })
            } else {
              // If data is missing for a month in a year, set y-value to 0
              series.data.push({ x: month + '', y: 0 })
            }
          })

          seriesData.push(series)
        })
        this.isYearSelected = true
        this.series = seriesData
        console.warn(this.series)
      })
    }
  }
}
</script>

<style></style>
