<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <v-data-table
          v-show="show"
          :headers="headers"
          :items="items"
          :search="search"
          items-per-page="5"
          @click:row="clickedArticle"
          class="datatable-row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                class="mt-8"
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                outlined
                dense
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.key.artNr="{ item }">
            <router-link
              :to="{
                name: 'Artikeldetails',
                params: { articleNummer: item.key.artNr }
              }"
              >{{ item.key.artNr }}</router-link
            >
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount + ' ' + item.amountType }}
          </template>
          <template v-slot:item.revenue="{ item }">
            {{ currencyFormatService.formatEuro(item.revenue) }}
          </template>
          <template v-slot:item.db="{ item }">
            {{ currencyFormatService.formatEuro(item.db) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import currencyFormatService from '@/services/formatters/currencyFormatService.js'
import axios from 'axios'

export default {
  data: () => ({
    show: false,
    headers: [
      { text: 'Nr.', value: 'key.artNr' },
      { text: 'Bezeichnung', value: 'name' },
      { text: 'Umsatz', value: 'revenue' },
      { text: 'Deckungsbeitrag', value: 'db' },
      { text: 'Menge', value: 'amount' }
    ],
    items: [],
    currencyFormatService,
    search: '',
    kdSortname: '',
    grouped: ''
  }),
  methods: {
    open(data, kdSortname, grouped) {
      this.kdSortname = kdSortname
      this.grouped = grouped
      this.items = data
      this.show = true
    },
    clickedArticle(article) {
      axios
        .get('v1/statistics/keyfigures/distributor/documents', {
          params: {
            kdSortname: this.kdSortname,
            artNr: article.key.artNr
          }
        })
        .then(data => {
          this.$emit('openDistributorDocuments', data.data)
        })
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
