<template>
  <v-menu
    v-model="selectedOpen"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
  >
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar flat class="border-bottom-primary-5">
        <v-avatar
          :color="selectedAppointment.scope.color"
          class="mr-4"
          size="26"
        ></v-avatar>
        <v-toolbar-title v-html="selectedAppointment.title"></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openAppointmentHistory"
          ><v-icon>mdi-history</v-icon></v-btn
        >
        <v-btn icon @click="quickOpenPictureUpload" v-if="!isLocked">
          <v-icon> mdi-upload </v-icon></v-btn
        >
        <v-btn icon @click="editAppointment" v-if="!isLocked">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-tooltip bottom v-if="isLocked">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-lock
            </v-icon>
          </template>
          <span>Dieser Termin ist gesperrt</span>
        </v-tooltip>

        <v-btn
          icon
          color="red darken-1"
          @click="tryDeleteAppointment"
          v-if="!isLocked"
        >
          <v-icon outlined>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <table>
          <tr v-for="s in setting" :key="s">
            <td v-if="s !== 'tel' && s !== 'address'">
              {{ contextMenuService.toGermanName(s) }}:
            </td>
            <td v-else-if="s === 'tel' && selectedAppointment.telNr !== null">
              {{ contextMenuService.toGermanName(s) }}
            </td>
            <td
              v-else-if="
                s === 'address' &&
                  selectedAppointment.firstAddressKdSortname !== null
              "
            >
              {{ contextMenuService.toGermanName(s) }}
            </td>
            <td class="pl-5">
              <template v-if="s === 'title'">{{
                selectedAppointment.title
              }}</template>
              <template v-if="s === 'from'">
                {{
                  dateTimeService.getGermanDateFormat(selectedAppointment.start)
                }}
              </template>
              <template v-if="s === 'to'">
                {{
                  dateTimeService.getGermanDateFormat(selectedAppointment.end)
                }}
              </template>
              <template v-if="s === 'scope'">
                {{ selectedAppointment.scope.name }}
              </template>
              <template v-if="s === 'location'">
                {{ selectedAppointment.location.name }}
              </template>
              <template v-if="s === 'type'">
                {{ selectedAppointment.type.name }}
              </template>
              <template v-if="s === 'state'">
                {{ selectedAppointment.state.description }}
              </template>
              <template
                v-if="
                  s === 'address' &&
                    selectedAppointment.firstAddressKdSortname != null
                "
              >
                {{ selectedAppointment.firstAddressKdSortname }}
              </template>
              <template
                v-if="s === 'tel' && selectedAppointment.telNr !== null"
              >
                {{ selectedAppointment.telNr }}
              </template>
            </td>
          </tr>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="red darken-1" @click="selectedOpen = false">
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
    <Loading ref="loading" />
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </v-menu>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'
import calendarService from '@/services/cal/calendarService.js'
import userSettingsService from '../../services/settings/userSettingsService'
import contextMenuService from '../../services/settings/contextMenuService'
import Loading from '@/components/calendar_v2/Loading.vue'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import WarningDialog from '@/components/core/WarningDialog.vue'
import colorService from '@/services/utils/colorService.js'

import { mapState } from 'vuex'

export default {
  data: () => ({
    selectedOpen: false,
    selectedAppointment: [],
    dateTimeService,
    errorDialog: false,
    failMessage: 'Es ist kein Terminverlauf vorhanden!',
    isLocked: false,
    setting: [],
    contextMenuService
  }),
  computed: {
    ...mapState({
      appointment: state => state.calAppointments.appointment,
      appointmentHistory: state => state.calAppointments.appointmentHistory,
      lockedAppointments: state => state.calAppointments.lockedAppointments
    })
  },
  components: {
    Loading,
    ErrorDialog,
    WarningDialog
  },
  methods: {
    open(nativeEvent, appointment) {
      const open = () => {
        this.selectedAppointment = appointment
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
      this.setting = userSettingsService
        .getValueFromName('contextMenu')
        .split(';')
        .filter(item => item != '')
      console.warn(this.setting)
      this.loadLockedAppointments(appointment)
    },
    loadLockedAppointments(appointment) {
      this.isLocked = false
      calendarService.isLocked(appointment).catch(error => {
        if (error.status === 409) {
          this.isLocked = true
        }
      })
    },
    editAppointment() {
      this.$emit('editAppointment', this.selectedAppointment)
    },
    tryDeleteAppointment() {
      this.$emit('tryDeleteAppointment', this.selectedAppointment)
    },
    deleteAppointment() {
      this.$store
        .dispatch('loadAppointment', this.selectedAppointment.key)
        .then(() => {
          if (this.appointment.serialNumber != 0) {
            var actions = [
              {
                action: 'single',
                text: 'Einzeltermin löschen',
                icon: 'mdi-calendar'
              },
              {
                action: 'serial',
                text: 'Serientermin löschen',
                icon: 'mdi-calendar-multiple'
              }
            ]
            this.$refs.warning.open(
              'Dieser Termin ist Teil einer Terminserie',
              actions
            )
          } else {
            this.$emit('deleteAppointment', this.selectedAppointment)
          }
        })
    },
    warningActionPerformed(action) {
      var isSerial = action === 'serial' ? true : false
      this.$emit('deleteAppointment', this.selectedAppointment, isSerial)
    },
    quickOpenPictureUpload() {
      this.$emit('quickOpenPictureUpload', this.selectedAppointment)
    },
    openAppointmentHistory() {
      this.$emit('openAppointmentHistory', this.selectedAppointment)
    },
    getEventTextColor(event) {
      return colorService.getTextColorForBackground(event.scope.color)
    }
  },
  watch: {
    selectedOpen: function() {
      var timeout = 5
      if (
        userSettingsService.getValueFromName('contextMenuDuration') !== null
      ) {
        timeout = userSettingsService.getValueFromName('contextMenuDuration')
      }
      if (this.selectedOpen == true) {
        setTimeout(() => {
          this.selectedOpen = false
        }, timeout * 1000)
      }
    }
  }
}
</script>

<style></style>
