<template>
  <v-dialog v-model="youSure" max-width="700px">
    <v-card>
      <v-card-title class="headline justify-center py-8">{{
        this.promptText
      }}</v-card-title>
      <v-card-actions class="pb-5 pr-5">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            color="red darken-1"
            class="white--text"
            @click="actionPerformed"
            >{{ this.actionOption }}</v-btn
          >
          <v-btn color="black" text @click="youSure = false">Abbrechen</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({ youSure: false, promptText: '', actionOption: '' }),
  methods: {
    open(prompt, action) {
      this.promptText = prompt
      this.actionOption = action
      this.youSure = true
    },
    actionPerformed() {
      this.$emit('actionPerformed')
      this.youSure = false
    }
  }
}
</script>

<style></style>
