var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"border-bottom-primary-5",attrs:{"flat":""}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"color":_vm.selectedAppointment.scope.color,"size":"26"}}),_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedAppointment.title)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openAppointmentHistory}},[_c('v-icon',[_vm._v("mdi-history")])],1),(!_vm.isLocked)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.quickOpenPictureUpload}},[_c('v-icon',[_vm._v(" mdi-upload ")])],1):_vm._e(),(!_vm.isLocked)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.editAppointment}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isLocked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-lock ")])]}}],null,false,2847554649)},[_c('span',[_vm._v("Dieser Termin ist gesperrt")])]):_vm._e(),(!_vm.isLocked)?_c('v-btn',{attrs:{"icon":"","color":"red darken-1"},on:{"click":_vm.tryDeleteAppointment}},[_c('v-icon',{attrs:{"outlined":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-card-text',[_c('table',_vm._l((_vm.setting),function(s){return _c('tr',{key:s},[(s !== 'tel' && s !== 'address')?_c('td',[_vm._v(" "+_vm._s(_vm.contextMenuService.toGermanName(s))+": ")]):(s === 'tel' && _vm.selectedAppointment.telNr !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.contextMenuService.toGermanName(s))+" ")]):(
              s === 'address' &&
                _vm.selectedAppointment.firstAddressKdSortname !== null
            )?_c('td',[_vm._v(" "+_vm._s(_vm.contextMenuService.toGermanName(s))+" ")]):_vm._e(),_c('td',{staticClass:"pl-5"},[(s === 'title')?[_vm._v(_vm._s(_vm.selectedAppointment.title))]:_vm._e(),(s === 'from')?[_vm._v(" "+_vm._s(_vm.dateTimeService.getGermanDateFormat(_vm.selectedAppointment.start))+" ")]:_vm._e(),(s === 'to')?[_vm._v(" "+_vm._s(_vm.dateTimeService.getGermanDateFormat(_vm.selectedAppointment.end))+" ")]:_vm._e(),(s === 'scope')?[_vm._v(" "+_vm._s(_vm.selectedAppointment.scope.name)+" ")]:_vm._e(),(s === 'location')?[_vm._v(" "+_vm._s(_vm.selectedAppointment.location.name)+" ")]:_vm._e(),(s === 'type')?[_vm._v(" "+_vm._s(_vm.selectedAppointment.type.name)+" ")]:_vm._e(),(s === 'state')?[_vm._v(" "+_vm._s(_vm.selectedAppointment.state.description)+" ")]:_vm._e(),(
                s === 'address' &&
                  _vm.selectedAppointment.firstAddressKdSortname != null
              )?[_vm._v(" "+_vm._s(_vm.selectedAppointment.firstAddressKdSortname)+" ")]:_vm._e(),(s === 'tel' && _vm.selectedAppointment.telNr !== null)?[_vm._v(" "+_vm._s(_vm.selectedAppointment.telNr)+" ")]:_vm._e()],2)])}),0)]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red darken-1"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Abbrechen ")])],1)],1),_c('Loading',{ref:"loading"}),_c('WarningDialog',{ref:"warning",on:{"warningActionPerformed":_vm.warningActionPerformed}}),_c('ErrorDialog',{attrs:{"failMessage":_vm.failMessage,"opened":_vm.errorDialog},on:{"closeDialog":function($event){_vm.errorDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }