<template>
  <div>
    <v-row class="mx-2">
      <v-select
        outlined
        dense
        label="Bestimmen nach..."
        :items="items"
        v-model="current"
        @change="loadData"
      ></v-select>
    </v-row>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <apexchart
          type="bar"
          height="500"
          :options="options"
          :series="series"
        ></apexchart> </v-col
    ></v-row></div
></template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      series: [],
      items: ['Einkauf', 'Verkauf', 'Deckungsbeitrag'],
      current: 'Einkauf'
    }
  },
  props: {},
  computed: {
    options: function() {
      return {
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {
          text: 'Stärkste Lieferanten'
        },
        yaxis: {
          type: 'category'
        },
        xaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: (e, chart, config) => {
              var kdSortname =
                config.w.globals.seriesX[config.seriesIndex][
                  config.dataPointIndex
                ]
              if (kdSortname !== undefined) {
                axios
                  .get('v1/statistics/keyfigures/distributor/grouped', {
                    params: {
                      kdSortname: kdSortname,
                      option: 'Artikeltypen'
                    }
                  })
                  .then(data => {
                    this.$emit('openDistributorGrouped', data.data, kdSortname)
                  })
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.current = 'Einkauf'
  },
  methods: {
    loadData() {
      axios
        .get('v1/statistics/keyfigures/distributor/top', {
          params: { option: this.current }
        })
        .then(data => {
          var types = []
          var seriesWrapper = []
          data.data.forEach(element => {
            for (var prop in element.y) {
              if (!types.includes(prop)) types.push(prop)
            }
          })
          types.forEach(type => {
            var series = { name: '', data: [] }
            series.name = type
            var dataSeries = []
            data.data.forEach(element => {
              dataSeries.push({ x: element.x, y: element.y[type] })
            })
            series.data = dataSeries
            seriesWrapper.push(series)
          })
          this.series = seriesWrapper
          console.warn(this.series)
        })
    }
  }
}
</script>

<style></style>
