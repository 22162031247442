<template>
  <v-dialog
    v-model="appointmentFilter"
    scrollable
    max-width="1000px"
    persistent
  >
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="appointmentFilter = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Termine filtern</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container justify="center" class="mt-4 mb-4">
          <v-row>
            <v-col cols="12" lg="6">
              <v-select
                prepend-inner-icon="mdi-calendar-check"
                class="mx-sm-1"
                outlined
                label="Status"
                :items="states"
                item-text="description"
                v-model="filter.states"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" lg="6">
              <v-select
                prepend-inner-icon="mdi-calendar-weekend-outline"
                class="mx-sm-1"
                outlined
                label="Bereich"
                :items="scopes"
                item-text="name"
                v-model="filter.scopes"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-select
                prepend-inner-icon="mdi-calendar-star-four-points"
                class="mx-sm-1"
                outlined
                label="Terminart"
                :items="types"
                item-text="name"
                v-model="filter.types"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" lg="6">
              <v-select
                prepend-inner-icon="mdi-map-marker"
                class="mx-sm-1"
                outlined
                label="Lokation"
                :items="locations"
                item-text="name"
                v-model="filter.locations"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn class="primary" @click="applyFilter()" elevation="0">
          Anwenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    appointmentFilter: false,
    filter: { addresses: [] },
    expansionPanelModel: [0],
    searchText: ''
  }),
  components: {},
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states
    })
  },
  methods: {
    open() {
      this.appointmentFilter = true
    },
    clearFilter() {
      if (this.filter.scopes !== undefined) this.filter.scopes.splice(0)
      if (this.filter.types !== undefined) this.filter.types.splice(0)
      if (this.filter.states !== undefined) this.filter.states.splice(0)
      if (this.filter.locations !== undefined) this.filter.locations.splice(0)
      this.filter.isSet = false
      this.$store.commit('SET_FILTER', this.filter)
      this.appointmentFilter = false
    },
    applyFilter() {
      console.warn(this.filter)
      //TODO: Filter auf null setzen, wenn Filter hier leer
      this.filter.isSet = true
      this.$store.commit('SET_FILTER', this.filter)
      this.appointmentFilter = false
      this.$emit('applyFilter')
    }
  }
}
</script>

<style></style>
