<template>
  <v-sheet id="calendarOverview" :height="calendarHeight">
    <v-calendar
      ref="calendarOverview"
      :events="appointmentPreviews"
      color="primary"
      v-model="focus"
      :event-ripple="false"
      :interval-height="intervalHeight"
      :event-margin-bottom="parseInt('2')"
      event-overlap-mode="column"
      :type="typeOfCalendarOverview"
      category-show-all
      :categories="categorieTypes"
      :category-text="categoryTextValue"
      :event-category="getEventCategory"
      :weekdays="weekdays"
      :show-week="true"
      :event-text-color="getEventTextColor"
      @change="updateRange"
      @click:event="showAppointmentContexMenu"
      @click:time="clickTime"
      @click:time-category="clickCategoryTime"
      @mousedown:event="startDrag"
      @mousedown:time="startTime"
      @mousemove:time="mouseMove"
      @mousemove:time-category="mouseMoveCategory"
      @mouseup:time="endDrag"
      @mouseleave.native="cancelDrag"
    >
      >
      <template v-slot:event="{ event }" class="no-text-select overflow-hidden">
        <span v-if="event.isLocked">Du bist gesperrt</span>
        <span v-else :style="{ color: getEventTextColor(event) }"
          ><span
            class="no-text-select overflow-hidden mb-1 mr-2"
            v-if="event.state.key.lfdnr === 100"
            ><v-icon color="white" small>mdi-check</v-icon></span
          >
          <span
            v-if="event.title != null"
            class="no-text-select overflow-hidden"
            >{{ event.title }}</span
          >
          <span class="no-text-select overflow-hidden" v-if="!event.allDay"
            >:
            {{
              dateTimeService.getTimeStringFromDateObject(new Date(event.start))
            }}
            -
            {{
              dateTimeService.getTimeStringFromDateObject(new Date(event.end))
            }}</span
          >

          <span
            v-if="event.firstAddressKdSortname != null"
            class="no-text-select overflow-hidden"
            ><br />{{ event.firstAddressKdSortname }}</span
          ></span
        >
      </template>
      <template v-slot:day-body="{ date, week }">
        <div
          class="v-current-time"
          :class="{ first: date === week[0].date }"
          :style="{ top: nowY }"
          id="v-current-time"
        ></div>
      </template>
    </v-calendar>
    <AppointmentPreviewContextMenu
      ref="contextMenu"
      @editAppointment="editAppointment"
      @deleteAppointment="deleteAppointment"
      @openAppointmentHistory="openAppointmentHistory"
      @quickOpenPictureUpload="quickOpenPictureUpload"
      @tryDeleteAppointment="tryDeleteAppointment"
    />
    <moveAppointmentDialog
      ref="moveAppointmentDialog"
      @undo="undo"
      @save="save"
    ></moveAppointmentDialog>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
    <Loading ref="loading" />
    <AppointmentHistory ref="history" />
    <QuickUploadAddressPictureDialog ref="quickUpload" />
    <WarningDialog
      ref="warning"
      @warningActionPerformed="triedDeleteAppointment"
    />
    <WarningDialog
      ref="warningSerial"
      @warningActionPerformed="isSerialToDelete"
    />
    <ReservedDataDialog
      ref="reserved"
      @editWithOption="saveReserved"
      @closeParent="closeReservedDataDialog"
    />
    <SerialReservedDataDialog
      ref="serialResDataDialog"
      @close="closeSerialReservedDataDialog"
    />
  </v-sheet>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService.js'
import AppointmentPreviewContextMenu from '@/components/calendar_v2/AppointmentPreviewContextMenu.vue'
import moveAppointmentDialog from './moveAppointmentDialog.vue'
import colorService from '@/services/utils/colorService.js'
import calendarService from '@/services/cal/calendarService.js'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import userSettingsService from '@/services/settings/userSettingsService'
import AppointmentHistory from '@/components/calendar_v2/AppointmentHistory.vue'
import Loading from '@/components/calendar_v2/Loading.vue'
import QuickUploadAddressPictureDialog from '@/components/calendar_v2/QuickUploadAddressPictureDialog.vue'
import WarningDialog from '../core/WarningDialog.vue'
import ReservedDataDialog from './ReservedDataDialog.vue'
import SerialReservedDataDialog from './SerialReservedDataDialog.vue'

export default {
  components: {
    AppointmentPreviewContextMenu,
    moveAppointmentDialog,
    // eslint-disable-next-line vue/no-unused-components
    calendarService,
    // eslint-disable-next-line vue/no-unused-components
    colorService,
    ErrorDialog,
    AppointmentHistory,
    Loading,
    QuickUploadAddressPictureDialog,
    WarningDialog,
    ReservedDataDialog,
    SerialReservedDataDialog
  },
  props: {
    navigationBarHeight: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    eventIsDragging: false,
    typeOfCalendarOverview: 'week',
    navigationDatePicker: '',
    navigationDate: '',
    focus: '',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    appointmentPreviews: [],
    dateTimeService,
    dragEvent: null,
    dragStart: null,
    lastEvent: '',
    createEvent: null,
    createStart: null,
    ready: false,
    categorieTypes: [],
    categoryTextValue: '',
    eventCategoryValue: '',
    category: '',
    timeout: null,
    timeoutEdit: null,
    timeoutCategory: null,
    openBecauseCategory: false,
    currentDraggedEvent: null,
    oldDragEvent: null,
    currentView: 'week',
    clickedAtAppointment: false,
    failMessage: 'Der Termin ist gesperrt!',
    errorDialog: false,
    isLocked: false,
    intervalHeight: 50,
    userSettingsService,
    appointmentToDelete: []
  }),
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      appointment: state => state.calAppointments.appointment,
      settingsCal: state => state.user.settings_cal,
      appointmentHistory: state => state.calAppointments.appointmentHistory
    }),
    cal() {
      return this.ready ? this.$refs.calendarOverview : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    calendarHeight() {
      return useWindowSize().height.value - 64 - this.navigationBarHeight
    }
  },
  methods: {
    zoom(plus, reset = false) {
      if (plus) {
        if (this.intervalHeight + 10 <= 100)
          this.intervalHeight = this.intervalHeight + 10
      } else {
        if (reset) {
          if (userSettingsService.getValueFromName('zoom') !== null) {
            this.intervalHeight = parseInt(
              userSettingsService.getValueFromName('zoom')
            )
          } else {
            this.intervalHeight = 50
          }
        } else {
          if (this.intervalHeight - 10 > 0)
            this.intervalHeight = this.intervalHeight - 10
        }
      }
    },
    closeSerialReservedDataDialog() {
      this.$refs.serialResDataDialog.serialReservedDataDialog = false
    },
    closeReservedDataDialog() {
      this.$refs.reserved.reservedDataDialog = false
    },
    getEventTextColor(event) {
      return colorService.getTextColorForBackground(event.scope.color)
    },
    getEventCategory(appointment) {
      if (this.category == 'scope') {
        var scope = JSON.parse(JSON.stringify(appointment.scope))
        scope.categoryName = scope.name
        return scope.name
      } else if (this.category == 'location') {
        var location = JSON.parse(JSON.stringify(appointment.location))
        location.categoryName = location.name
        return location.name
      }
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0
    },
    scrollToTime() {
      const time = parseInt(
        userSettingsService.getValueFromName('startTimeView')
      )
      const first = Math.max(0, time - (time % 30))

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    startDrag(e) {
      if (e.event && e.timed) {
        this.eventIsDragging = true

        this.dragEvent = e.event
        this.oldDragEvent = { ...this.dragEvent } // save the old event
        this.dragTime = null
        this.extendOriginal = null
      }

      this.lastEvent = 'startDrag'
    },
    startTime(e) {
      const mouse = this.toDate(e)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.toDate(this.dragEvent.start)

        this.dragTime = mouse.getTime() - start.getTime()
      }
      this.lastEvent = 'startTime'
    },
    mouseMoveEvent(e) {
      console.log('mouseMoveEvent', e)
    },
    mouseMove(e) {
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.toDate(this.dragEvent.start)
        const end = this.toDate(this.dragEvent.end)
        const duration = end.getTime() - start.getTime()
        const mouse = this.toDate(e)

        const newStartTime = mouse.getTime() - this.dragTime
        const newStart = new Date(this.roundTime(newStartTime))
        const newEnd = new Date(newStart.getTime() + duration)

        this.dragEvent.start = this.toTimestamp(newStart)
        this.dragEvent.end = this.toTimestamp(newEnd)
      }
    },
    mouseMoveCategory(tms) {
      if (this.currentView == 'scope') {
        this.dragEvent.scope = tms.category
        this.dragEvent.color = tms.category.color
      } else if (this.currentView == 'location') {
        this.dragEvent.location = tms.category
      }
    },
    endDrag() {
      calendarService
        .isLocked(this.oldDragEvent)
        .catch(error => {
          if (error.status === 409) {
            this.errorDialog = true
            this.undo()
          }
        })
        .then(() => {
          if (this.errorDialog == false) {
            this.$refs.moveAppointmentDialog.open(
              this.oldDragEvent,
              this.dragEvent
            )
          }
        })

      this.resetDragState()
      this.lastEvent = 'endDrag'
    },
    cancelDrag() {
      this.resetDragState()
      this.lastEvent = 'cancelDrag'
    },
    roundTime(time, down = true) {
      const roundDownTime = 15 * 60 * 1000 // 15 minutes

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toDate(tms) {
      return typeof tms === 'string'
        ? new Date(tms)
        : new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute)
    },
    toTimestamp(date) {
      return `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    },
    resetDragState() {
      this.dragTime = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null

      //this.oldDragEvent = null

      setTimeout(function() {
        this.eventIsDragging = false
      }, 1000)
    },
    saveReserved(reservedAppointment, option = null) {
      this.save(reservedAppointment, false, option)
    },
    save(newAppointment, isSerial = false, option = null) {
      this.resetDragState()
      this.dragEvent = null

      let tempAppointment = JSON.parse(JSON.stringify(newAppointment))
      this.$store.dispatch('loadAppointment', tempAppointment.key).then(() => {
        tempAppointment = this.appointment
        tempAppointment.start = dateTimeService.getDateTimeStringFromDate(
          new Date(newAppointment.start)
        )
        tempAppointment.end = dateTimeService.getDateTimeStringFromDate(
          new Date(newAppointment.end)
        )
        if (this.currentView == 'scope') {
          tempAppointment.scope = newAppointment.scope
        } else if (this.currentView == 'location') {
          tempAppointment.location = newAppointment.location
        }

        if (isSerial) {
          console.log('Serial update')
          calendarService
            .updateSerialAppointment(tempAppointment)
            .catch(error => {
              console.log(error)
              if (error.response.status == 409) {
                console.log('Reserved')
                this.$refs.serialResDataDialog.open(error.response.data, 'edit')
              }
            })
            .then(() => {
              this.$toast.success('Terminserie wurde gespeichert')
            })
        } else {
          console.log(option)
          if (option == null) {
            calendarService
              .updateAppointment(tempAppointment)
              .catch(reserved => {
                console.log(reserved)
                this.$refs.reserved.open(reserved, 'edit')
              })
              .then(() => {
                if (this.$refs.reserved.reservedDataDialog == false) {
                  this.$toast.success('Termin wurde gespeichert')
                }
              })
          } else {
            tempAppointment.conflictResolution = option
            calendarService.updateAppointment(tempAppointment).then(() => {
              this.editAppointment = false
              this.$toast.success('Termin wurde gespeichert')
            })
          }
        }
      })
    },
    undo() {
      this.dragEvent.start = this.oldDragEvent.start
      this.dragEvent.end = this.oldDragEvent.end
      this.dragEvent.scope = this.oldDragEvent.scope
      this.dragEvent.location = this.oldDragEvent.location
      this.dragEvent.color = this.oldDragEvent.color

      this.resetDragState()
      this.dragEvent = null
    },

    updateRange({ start, end }) {
      this.$emit(
        'updateRange',
        this.$refs.calendarOverview.getStartOfWeek(start).date,
        this.$refs.calendarOverview.getEndOfWeek(end).date
      )

      this.sendTitle()
    },
    setToday() {
      this.focus = ''
      this.scrollToTime()
    },
    setToDate(date) {
      this.focus = date
    },
    sendTitle() {
      this.$emit('monthYearTitleChanged', this.$refs.calendarOverview.title)
    },
    nextPage() {
      this.$refs.calendarOverview.next()
      this.sendTitle()
    },
    prevPage() {
      this.$refs.calendarOverview.prev()
      this.sendTitle()
    },
    changeTypeOfView(newView, days = 0) {
      if (newView == 'week') {
        this.typeOfCalendarOverview = 'week'
        this.currentView = 'week'
        switch (days) {
          case 5:
            this.weekdays = [1, 2, 3, 4, 5]
            break
          case 7:
            this.weekdays = [1, 2, 3, 4, 5, 6, 0]
            break
          default:
            break
        }
      } else if (newView == 'scope') {
        this.currentView = 'scope'
        this.categorieTypes = []
        this.scopes.forEach(element => {
          this.categorieTypes.push(element)
        })
        this.categoryTextValue = 'name'
        this.eventCategoryValue = 'scope'
        this.typeOfCalendarOverview = 'category'
        this.category = 'scope'
      } else if (newView == 'location') {
        this.currentView = 'location'
        this.categorieTypes = []
        this.locations.forEach(element => {
          this.categorieTypes.push(element)
        })
        this.categoryTextValue = 'name'
        this.eventCategoryValue = 'location'
        this.typeOfCalendarOverview = 'category'
        this.category = 'location'
      } else {
        this.weekdays = [1, 2, 3, 4, 5, 6, 0]
        this.typeOfCalendarOverview = newView
      }
      this.sendTitle()
    },
    navigateToDate(navigationDate) {
      this.focus = navigationDate
      setTimeout(() => {
        this.sendTitle()
      }, 1)
    },
    showAppointmentContexMenu({ nativeEvent, event }) {
      if (this.dragEvent.start != this.oldDragEvent.start) {
        return false
      }

      if (!this.timeoutEdit) {
        this.timeoutEdit = setTimeout(() => {
          this.timeoutEdit = null
          this.dragEvent = null
          this.$refs.contextMenu.open(nativeEvent, event)
        }, 350)
      } else {
        clearTimeout(this.timeoutEdit)
        this.clickedAtAppointment = true
        calendarService
          .isLocked(event)
          .catch(error => {
            if (error.status === 409) {
              this.errorDialog = true
              this.timeoutEdit = null
            }
          })
          .then(() => {
            if (this.errorDialog == false) {
              this.editAppointment(event)
              this.clickedAtAppointment = false
              this.timeoutEdit = null
            }
          })
      }
    },
    editAppointment(appointment) {
      this.$emit('editAppointment', appointment)
    },
    tryDeleteAppointment(selectedAppointment) {
      this.appointmentToDelete = selectedAppointment
      var actions = [
        {
          icon: 'mdi-delete',
          text: 'Löschen',
          action: 'delete'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]

      this.$refs.warning.open(
        'Wollen Sie den Termin löschen?',
        actions,
        selectedAppointment
      )
    },
    triedDeleteAppointment(action) {
      if (action == 'cancel') {
        console.log('Canceled')
        this.appointmentToDelete = null
        return
      }
      this.$store
        .dispatch('loadAppointment', this.appointmentToDelete.key)
        .then(() => {
          if (this.appointment.serialNumber != 0) {
            var actions = [
              {
                action: 'single',
                text: 'Einzeltermin löschen',
                icon: 'mdi-calendar'
              },
              {
                action: 'serial',
                text: 'Serientermin löschen',
                icon: 'mdi-calendar-multiple'
              }
            ]
            this.$refs.warningSerial.open(
              'Dieser Termin ist Teil einer Terminserie',
              actions
            )
          } else {
            this.deleteAppointment(this.appointmentToDelete)
          }
        })
    },
    isSerialToDelete(action) {
      var isSerial = action === 'serial' ? true : false
      this.deleteAppointment(this.appointmentToDelete, isSerial)
    },
    deleteAppointment(appointment, isSerial = false) {
      this.$emit('deleteAppointment', appointment, isSerial)
    },
    clickTime(tms) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          this.timeout = null
        }, 350)
      } else {
        clearTimeout(this.timeout)
        this.timeout = null
        if (!this.openBecauseCategory && !this.clickedAtAppointment)
          this.addAppointment(tms)
        if (this.openBecauseCategory) this.openBecauseCategory = false
      }
    },
    // eslint-disable-next-line no-unused-vars
    clickCategoryTime(tms) {
      if (!this.timeoutCategory) {
        this.timeoutCategory = setTimeout(() => {
          this.timeoutCategory = null
        }, 350)
      } else {
        // eslint-disable-next-line no-unused-vars
        var categoryObject = []
        // eslint-disable-next-line no-unused-vars
        var categoryType = ''
        switch (this.category) {
          case 'location':
            categoryType = 'location'
            categoryObject = tms.category
            this.locations.forEach(element => {
              if (element.key.lfdnr === tms.category.key.lfdnr) {
                categoryObject = element
              }
            })
            break
          case 'scope':
            categoryType = 'scope'
            this.scopes.forEach(element => {
              if (element.key.lfdnr === tms.category.key.lfdnr) {
                categoryObject = element
              }
            })
            break
        }

        var category = {
          type: categoryType,
          object: categoryObject
        }
        this.addAppointment(tms, category)
      }
    },
    addAppointment(tms, category = '') {
      var date = new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      )
      var coeff = 1000 * 60 * 5
      var roundedToFive = new Date(Math.round(date.getTime() / coeff) * coeff)
      if (category === '' && this.category === '') {
        this.$emit('addAppointment', roundedToFive)
      } else {
        this.openBecauseCategory = true
        this.$emit('addAppointment', roundedToFive, category)
        //this.openBecauseCategory = false
      }
    },
    getValueFromName(name) {
      return this.$store.getters.settings_cal.find(s => s.valueName === name)
        .value
    },
    setInitialZoom() {
      if (userSettingsService.getValueFromName('zoom') !== null) {
        this.intervalHeight = parseInt(
          userSettingsService.getValueFromName('zoom')
        )
      } else {
        this.intervalHeight = 50
      }
    },
    setInitialTypeOfCalendarOverview() {
      var type = userSettingsService.getValueFromName('typeOfCalendarOverview')
      if (type !== null) {
        if (type === '5week') {
          this.changeTypeOfView('week', 5)
        } else if (type === '7week') {
          this.changeTypeOfView('week', 7)
        } else {
          this.changeTypeOfView(type)
        }
      } else {
        this.typeOfCalendarOverview = 'week'
        this.weekdays = [1, 2, 3, 4, 5, 6, 0]
      }
    },
    openAppointmentHistory(selectedAppointment) {
      this.$refs.loading.open('Terminverlauf wird geladen ...')
      this.$store
        .dispatch('loadAppointmentHistory', selectedAppointment)
        .then(() => {
          this.$refs.loading.close()
          if (this.appointmentHistory.status == 204) {
            this.errorDialog = true
          } else {
            this.$refs.history.open(this.appointmentHistory.data)
          }
        })
    },
    quickOpenPictureUpload(selectedAppointment) {
      this.$refs.loading.open('Termindetails werden geladen...')
      this.$store
        .dispatch('loadAppointment', selectedAppointment.key)
        .then(() => {
          this.$refs.quickUpload.open(this.appointment)
          this.$refs.loading.close()
        })
    }
  },

  mounted() {
    this.sendTitle()
    this.ready = true
    this.scrollToTime()
    this.updateTime()
    this.setInitialZoom()
    this.setInitialTypeOfCalendarOverview()
  },
  watch: {
    category: function() {
      this.$emit('setCategoryProp', this.category)
    }
  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: var(--v-primary-base);
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: var(--v-primary-base);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.no-text-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
}
.overflow-hidden {
  overflow: hidden;
}
.v-event-timed {
  overflow: hidden;
}
</style>
