<template> <Loading ref="loading"></Loading></template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/calendar_v2/Loading.vue'

export default {
  computed: {
    ...mapGetters(['settings_cal', 'locations', 'scopes']),
    needsToBeLoaded() {
      return `${this.settings_cal}|${this.scopes}|${this.locations}`
    }
  },
  components: {
    Loading
  },
  mounted() {
    this.$refs.loading.open('Die Einstellungen werden geladen...')
    this.$store.dispatch('loadScopes')
    this.$store.dispatch('loadLocations')

    this.$watch(
      vm => [vm.settings_cal, vm.scopes, vm.locations],
      () => {
        if (
          this.settings_cal !== undefined &&
          this.scopes !== undefined &&
          this.locations !== undefined
        ) {
          this.$router.push('/calv2')
        }
      }
    )
  }
}
</script>

<style></style>
