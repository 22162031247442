<template>
  <div>
    <v-expansion-panels
      accordion
      class="mb-4"
      flat
      style="border: 1px #757575 solid"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3>
            <v-icon class="mr-2">mdi-filter</v-icon>Filter
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="mb-n9">
            <v-col cols="6"
              ><v-select
                outlined
                dense
                label="Von (Jahr)"
                :items="years"
                v-model="filter.startYear"
              ></v-select
            ></v-col>
            <v-col cols="6"
              ><v-select
                outlined
                dense
                :items="years"
                label="Bis (Jahr)"
                v-model="filter.endYear"
              ></v-select
            ></v-col>
          </v-row>
          <v-row class="mb-n9">
            <v-col cols="12">
              <v-autocomplete
                outlined
                dense
                :items="distributors"
                label="Lieferant"
                item-text="kdSortname"
                v-model="filter.distributors"
                :item-value="item => item"
                chips
                small-chips
                deletable-chips
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-btn
              color="primary"
              elevation="0"
              class="mr-5 ml-3"
              @click="updateFilter"
              >Übernehmen</v-btn
            >
            <v-btn color="error" elevation="0" @click="clearFilter"
              >Zurücksetzen</v-btn
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    filter: {
      startYear: '',
      endYear: '',
      distributors: []
    },
    years: [],
    distributors: [],
    topOptions: [
      'Einkauf',
      'Verkauf',
      'Deckungsbeitrag',
      'Deckungsbeitragsspanne (Prozent)'
    ]
  }),
  mounted() {
    this.loadMinYear()
    this.loadFilterData()
    this.updateFilter()
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date()
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year)
        }
      })
    },
    loadFilterData() {
      axios.get('v1/statistics/filter/distributors').then(data => {
        this.distributors = data.data
      })
    },
    updateFilter() {
      axios
        .post('v1/statistics/keyfigures/filter/distributor', this.filter)
        .then(() => {
          this.$emit('loadData')
        })
    },
    clearFilter() {
      this.filter = {
        startYear: '',
        endYear: '',
        distributors: []
      }
      this.updateFilter()
    }
  }
}
</script>

<style></style>
