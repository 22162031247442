<template>
  <div class="mt-6 mx-4 mb-4">
    <YearMonthFilter @loadData="loadDataYearMonth"></YearMonthFilter>

    <v-row
      ><v-col lg="6" md="12" sm="12" xs="12">
        <YearComparison ref="year"></YearComparison>
      </v-col>
      <v-col lg="6" md="12" sm="12" xs="12"
        ><MonthComparison ref="month"></MonthComparison></v-col
    ></v-row>
    <v-row>
      <v-col cols="12">
        <FilteredFilter @loadData="loadFiltered"></FilteredFilter>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
        ><FilterComparison ref="filtered"></FilterComparison
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import YearComparison from '@/components/bi/revenue/YearComparison.vue'
import MonthComparison from '../../components/bi/revenue/MonthComparison.vue'
import FilterComparison from '../../components/bi/revenue/FilterComparison.vue'
import YearMonthFilter from '../../components/bi/revenue/YearMonthFilter.vue'
import FilteredFilter from '../../components/bi/revenue/FilteredFilter.vue'

export default {
  components: {
    YearComparison,
    FilterComparison,
    YearMonthFilter,
    FilteredFilter,
    MonthComparison
  },
  methods: {
    loadDataYearMonth() {
      this.$refs.year.loadData()
      this.$refs.month.loadData()
    },
    loadFiltered() {
      this.$refs.filtered.loadData()
    }
  }
}
</script>
