<template>
  <div>
    <Loading ref="loading"></Loading>
    <EditAppointment ref="editAppointment" />
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />

    <NavigationBar
      id="calNav"
      :monthYearTitle="monthYearTitle"
      ref="nav"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @setToday="setToday"
      @changeTypeOfView="changeTypeOfView"
      @navigateToDate="navigateToDate"
      @addAppointment="addAppointment"
      @editAppointment="editAppointment"
      @deleteAppointment="deleteAppointment"
      @setToDate="setToDate"
      @applyFilter="applyFilter"
      @clearFilter="clearFilter"
      @zoom="zoom"
    />

    <CalendarOverview
      v-if="isCalendar"
      ref="calendarOverview"
      :navigationBarHeight="navigationBarHeight"
      @monthYearTitleChanged="updateMonthYearTitle"
      @updateRange="updateRange"
      @editAppointment="editAppointment"
      @deleteAppointment="deleteAppointment"
      @setCategoryProp="setCategoryProp"
      @addAppointment="addAppointment"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CalendarOverview from '@/components/calendar_v2/CalendarOverview.vue'
import NavigationBar from '@/components/calendar_v2/NavigationBar.vue'
import Loading from '@/components/calendar_v2/Loading.vue'
import EditAppointment from '@/components/calendar_v2/EditAppointment.vue'
import calendarService from '@/services/cal/calendarService.js'
import dateTimeService from '@/services/cal/dateTimeService.js'
import WarningDialog from '@/components/core/WarningDialog.vue'
import stompService from '@/services/stomp/stomp.service'

export default {
  data: function() {
    return {
      monthYearTitle: null,
      isMounted: false
    }
  },
  components: {
    CalendarOverview,
    NavigationBar,
    Loading,
    EditAppointment,
    // eslint-disable-next-line vue/no-unused-components
    calendarService,
    WarningDialog
  },
  async mounted() {
    this.isMounted = true
    await this.$store.dispatch('loadScopes')
    this.$store.dispatch('loadLocations')
    this.$store.dispatch('loadTypes')
    this.$store.dispatch('loadStates')
    this.$store.dispatch('loadResources')
    this.subscribeTopics()
  },
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states,
      appointmentPreviews: state => state.calAppointments.appointmentPreviews,
      appointment: state => state.calAppointments.appointment,
      filter: state => state.calAppointments.filter
    }),
    isCalendar() {
      if (this.$route.name == 'Calendar-v2') return true

      return false
    },
    navigationBarHeight() {
      if (!this.isMounted) return
      //const nav = this.$refs.nav
      const elementSize = document.getElementById('calNav').clientHeight
      //console.debug(elementSize.height)
      return elementSize
    }
  },
  methods: {
    zoom(plus, reset = false) {
      this.$refs.calendarOverview.zoom(plus, reset)
      this.$refs.nav.zoomValue =
        Math.round((this.$refs.calendarOverview.intervalHeight / 50) * 100) +
        '%'
    },
    applyFilter() {
      var filtered = this.appointmentPreviews
      if (this.filter.scopes !== undefined && this.filter.scopes.length != 0) {
        filtered = this.filterProperty(
          filtered,
          this.filter.scopes,
          'scope',
          'lfdnr'
        )
      }
      if (
        this.filter.locations !== undefined &&
        this.filter.locations.length != 0
      ) {
        filtered = this.filterProperty(
          filtered,
          this.filter.types,
          'type',
          'lfdnr'
        )
      }
      if (this.filter.states !== undefined && this.filter.states.length != 0) {
        filtered = this.filterProperty(
          filtered,
          this.filter.states,
          'state',
          'lfdnr'
        )
      }
      if (this.filter.types !== undefined && this.filter.types.length != 0) {
        filtered = this.filterProperty(
          filtered,
          this.filter.types,
          'type',
          'lfdnr'
        )
      }

      this.$refs.calendarOverview.appointmentPreviews = filtered
    },
    filterProperty(toFilter, filter, property, identifier) {
      var result = []
      var keys = filter.map(f => f.key[identifier])

      toFilter.forEach(a => {
        if (keys.includes(a[property].key[identifier])) {
          result.push(a)
        }
      })

      return result
    },

    clearFilter() {
      this.handleLoadedAppointments(this.appointmentPreviews)
    },
    handleLoadedAppointments(appointments) {
      appointments.forEach(appointment => {
        appointment.color = appointment.scope.color
        if (appointment.allDay) {
          appointment.start = dateTimeService.getDateStringFromDateTimeString(
            appointment.start
          )
          appointment.end = dateTimeService.getDateStringFromDateTimeString(
            appointment.end
          )
        }
      })
      if (
        this.$refs.calendarOverview.category == 'scope' ||
        this.$refs.calendarOverview.category == 'location'
      ) {
        this.setCategoryProp(this.$refs.calendarOverview.category)
      } else {
        if (!this.filter.isSet)
          this.$refs.calendarOverview.appointmentPreviews = appointments
      }
    },
    updateRange(start, end) {
      this.$refs.loading.open('Termine werden geladen...')
      this.$store
        .dispatch('loadAppointmentPreviews', { start, end })
        .then(() => {
          if (this.filter.isSet) {
            this.applyFilter()
          }

          this.handleLoadedAppointments(this.appointmentPreviews)
          this.$refs.loading.close()
        })
    },
    setCategoryProp(category) {
      console.log(category)
      this.$refs.calendarOverview.appointmentPreviews = this.appointmentPreviews
    },
    updateMonthYearTitle(title) {
      this.monthYearTitle = title
    },
    nextPage() {
      this.$refs.calendarOverview.nextPage()
    },
    prevPage() {
      this.$refs.calendarOverview.prevPage()
    },
    setToday() {
      this.$refs.calendarOverview.setToday()
    },
    setToDate(date) {
      this.$refs.calendarOverview.setToDate(date)
    },
    changeTypeOfView(newView, days) {
      this.$refs.calendarOverview.changeTypeOfView(newView, days)
    },
    navigateToDate(navigationDate) {
      this.$refs.calendarOverview.navigateToDate(navigationDate)
    },
    warningActionPerformed(action) {
      var appointment = this.$refs.warning.additionalData
      var isSerial = action === 'serial' ? true : false
      this.editAppointment(appointment, isSerial, true)
    },
    editAppointment(appointment, editAsSerial = false, userChose = false) {
      console.log(appointment)
      this.$refs.loading.open('Termindetails werden geladen ...')
      this.$store.dispatch('loadAppointment', appointment.key).then(() => {
        this.$refs.editAppointment.appointment = this.appointment
        this.$refs.loading.close()
        if (
          appointment.serialNumber != 0 &&
          userChose == false &&
          editAsSerial == false
        ) {
          var actions = [
            {
              action: 'single',
              text: 'Einzeltermin bearbeiten',
              icon: 'mdi-calendar'
            },
            {
              action: 'serial',
              text: 'Serientermin bearbeiten',
              icon: 'mdi-calendar-multiple'
            }
          ]
          this.$refs.warning.open(
            'Dieser Termin ist Teil einer Terminserie',
            actions,
            appointment
          )
          this.$refs.calendarOverview.clickedAtAppointment = false
        } else this.$refs.editAppointment.open('edit', '', '', editAsSerial)
      })
    },
    deleteAppointment(appointment, isSerial = false) {
      if (isSerial) {
        calendarService
          .deleteSerialAppointment(appointment.serialNumber)
          .then(() => {
            this.$toast.success('Terminserie wurde gelöscht')
          })
      } else {
        calendarService.deleteAppointment(appointment.key).then(() => {
          this.$toast.success('Termin wurde gelöscht')
        })
      }
    },
    addAppointment(date = '', category = '') {
      this.$refs.editAppointment.clearData('add')
      this.$refs.editAppointment.open('add', date, category)
    },
    handleWebsocketAppointment(appointment) {
      appointment.color = appointment.scope.color
      if (appointment.allDay) {
        appointment.start = dateTimeService.getDateStringFromDateTimeString(
          appointment.start
        )
        appointment.end = dateTimeService.getDateStringFromDateTimeString(
          appointment.end
        )
      }

      if (this.$refs.calendarOverview.typeOfCalendarOverview == 'category') {
        this.setCategoryProp(this.$refs.calendarOverview.category)
      }
      return appointment
    },
    receiveAppointmentPreview(appointmentPreview) {
      appointmentPreview = this.handleWebsocketAppointment(appointmentPreview)
      this.$refs.calendarOverview.appointmentPreviews.push(appointmentPreview)
    },
    receiveAppointmentPreviewUpdate(appointmentObject) {
      var appointmentPreview = appointmentObject.appointmentPreview
      appointmentPreview = this.handleWebsocketAppointment(appointmentPreview)
      var foundAppointmentPreview = this.$refs.calendarOverview.appointmentPreviews.find(
        foundAppointmentPreview =>
          foundAppointmentPreview.key.lfdnr == appointmentPreview.key.lfdnr &&
          foundAppointmentPreview.key.jahr == appointmentPreview.key.jahr &&
          foundAppointmentPreview.key.bereich ==
            appointmentObject.oldScopeLfdnr &&
          foundAppointmentPreview.key.firma == appointmentPreview.key.firma
      )
      console.log(appointmentPreview)
      foundAppointmentPreview &&
        Object.assign(foundAppointmentPreview, appointmentPreview)
    },
    receiveAppointmentPreviewDeletion(appointmentPreview) {
      var foundAppointmentPreview = this.$refs.calendarOverview.appointmentPreviews.find(
        foundAppointmentPreview =>
          foundAppointmentPreview.key.lfdnr == appointmentPreview.lfdnr &&
          foundAppointmentPreview.key.jahr == appointmentPreview.jahr &&
          foundAppointmentPreview.key.bereich == appointmentPreview.bereich &&
          foundAppointmentPreview.key.firma == appointmentPreview.firma
      )

      this.$refs.calendarOverview.appointmentPreviews.splice(
        this.$refs.calendarOverview.appointmentPreviews.indexOf(
          foundAppointmentPreview
        ),
        1
      )
    },
    lockAppointment(appointment) {
      console.warn(appointment)
    },
    async subscribeTopics() {
      if (!stompService.connected) {
        await stompService.connect()
      }
      this.scopes.forEach(scope => {
        stompService.subscribe(
          `/topic/cal/appointment/${scope.key.lfdnr}/new`,
          this.receiveAppointmentPreview
        )
        stompService.subscribe(
          `/topic/cal/appointment/${scope.key.lfdnr}/update`,
          this.receiveAppointmentPreviewUpdate
        )
        stompService.subscribe(
          `/topic/cal/appointment/${scope.key.lfdnr}/deletion`,
          this.receiveAppointmentPreviewDeletion
        )
      })
      stompService.subscribe(
        '/topic/cal/appointment/lock',
        this.lockAppointment
      )
    }
  }
}
</script>

<style scoped></style>
