<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <apexchart type="bar" height="500" :options="options" :series="series">
        </apexchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: function() {
    return {
      categories: ['Standort'],
      series: [],
      filter: {
        filterCategory: '',
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: ''
      },
      years: []
    }
  },
  mounted() {},
  methods: {
    loadData() {
      axios.get('v1/statistics/revenue/filtered').then(data => {
        var types = []
        var seriesWrapper = []
        data.data.forEach(element => {
          for (var prop in element.y) {
            if (!types.includes(prop)) types.push(prop)
          }
        })
        types.forEach(type => {
          var series = { name: '', data: [] }
          series.name = type
          var dataSeries = []
          data.data.forEach(element => {
            dataSeries.push({ x: element.x, y: element.y[type] })
          })
          series.data = dataSeries
          seriesWrapper.push(series)
        })
        this.series = seriesWrapper
        console.warn(this.series)
      })
    }
  },
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {
          text: 'Kategorievergleich'
        },
        xaxis: {
          type: 'category'
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      }
    }
  }
}
</script>

<style></style>
