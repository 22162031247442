<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <apexchart
          v-show="show"
          height="500"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart> </v-col
    ></v-row></div
></template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      series: [],
      show: false,
      formGroup: ''
    }
  },
  props: {},
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Deckungsbeitrag: ' + this.formGroup
        },
        xaxis: {
          type: 'category'
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      }
    }
  },
  mounted() {},
  methods: {
    loadData(formGroup = '', show = false) {
      if (formGroup !== '') {
        this.formGroup = formGroup
      }
      axios
        .get('v1/statistics/keyfigures/document/db', {
          params: {
            formGroup: this.formGroup
          }
        })
        .then(data => {
          var types = []
          var seriesWrapper = []
          data.data.forEach(element => {
            for (var prop in element.y) {
              if (!types.includes(prop)) types.push(prop)
            }
          })
          types.forEach(type => {
            var series = { name: '', data: [] }
            series.name = type
            var dataSeries = []
            data.data.forEach(element => {
              dataSeries.push({ x: element.x, y: element.y[type] })
            })
            series.data = dataSeries
            seriesWrapper.push(series)
          })
          this.series = seriesWrapper
          if (show) {
            this.show = true
          }
        })
    }
  }
}
</script>

<style></style>
