<template>
  <div>
    <v-row>
      <v-col md="12" lg="12" sm="12" xs="12">
        <apexchart
          type="bar"
          height="500"
          :options="options"
          :series="series"
        ></apexchart> </v-col
    ></v-row></div
></template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      series: []
    }
  },
  props: {},
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {
          text: 'Dokumentanzahl'
        },
        xaxis: {
          type: 'category'
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: (e, chart, config) => {
              var formGroup =
                config.w.globals.seriesX[config.seriesIndex][
                  config.dataPointIndex
                ]
              if (formGroup !== undefined) {
                this.$emit('openDocumentRevenue', formGroup)
              }
            }
          }
        }
      }
    }
  },
  mounted() {},
  methods: {
    loadData() {
      axios.get('v1/statistics/keyfigures/document/count').then(data => {
        var types = []
        var seriesWrapper = []
        data.data.forEach(element => {
          for (var prop in element.y) {
            if (!types.includes(prop)) types.push(prop)
          }
        })
        types.forEach(type => {
          var series = { name: '', data: [] }
          series.name = type
          var dataSeries = []
          data.data.forEach(element => {
            dataSeries.push({ x: element.x, y: element.y[type] })
          })
          series.data = dataSeries
          seriesWrapper.push(series)
        })
        this.series = seriesWrapper
      })
    }
  }
}
</script>

<style></style>
