<template
  ><div class="mt-4">
    <v-switch
      v-model="adminView"
      :label="adminViewLabel"
      class="mx-4"
      :disabled="!isAdmin"
    ></v-switch>
    <v-tabs grow v-model="tab">
      <v-tab key="open">Offene Aufgaben</v-tab>
      <v-tab key="done">Abgeschlossene Aufgaben</v-tab
      ><v-tab key="all">Alle Aufgaben</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        key="open"
        class="mt-6 mx-4 mb-4"
        transition="no-transition"
        reverse-transition="no-transition"
        ><TaskTable :items="taskPreviews_open" v-if="adminView === true" />
        <TaskTable :items="taskPreviewsUser_open" v-if="adminView === false" />
      </v-tab-item>
      <v-tab-item
        key="all"
        class="mt-6 mx-4 mb-4"
        transition="no-transition"
        reverse-transition="no-transition"
        ><TaskTable
          :items="taskPreviews_done"
          v-if="adminView === true"/><TaskTable
          :items="taskPreviewsUser_done"
          v-if="adminView === false"
      /></v-tab-item>
      <v-tab-item
        key="done"
        class="mt-6 mx-4 mb-4"
        transition="no-transition"
        reverse-transition="no-transition"
        ><TaskTable :items="taskPreviews" v-if="adminView === true"/><TaskTable
          :items="taskPreviewsUser"
          v-if="adminView === false"/></v-tab-item
    ></v-tabs-items></div
></template>

<script>
import TaskTable from '@/components/task/TaskTable.vue'
import { mapState } from 'vuex'

export default {
  data: () => ({
    tab: 'open',
    adminView: false
  }),
  components: {
    TaskTable
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      taskPreviews: state => state.task.taskPreviews,
      taskPreviews_open: state => state.task.taskPreviews_open,
      taskPreviews_done: state => state.task.taskPreviews_done,
      taskPreviewsUser: state => state.task.taskPreviewsUser,
      taskPreviewsUser_open: state => state.task.taskPreviewsUser_open,
      taskPreviewsUser_done: state => state.task.taskPreviewsUser_done
    }),
    adminViewLabel: function() {
      var returnString = 'Adminansicht: '
      if (this.adminView) returnString = returnString + 'aktiviert'
      else returnString = returnString + 'deaktiviert'

      return returnString
    },
    isAdmin() {
      return this.user.userLvl >= 250 ? true : false
    }
  },
  mounted() {
    this.$store.dispatch('loadUserData').then(() => {
      this.$store.dispatch('loadTaskPreviewsForUser')
      if (this.user.userLvl >= 250) this.$store.dispatch('loadTaskPreviews')
    })
  }
}
</script>

<style></style>
